import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Grid, InputAdornment, Typography } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useRef } from 'react';
import Countdown from 'react-countdown';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleBackground from '../../Components/Background/Simple';
import CountryInput from '../../Components/UI/CountryInput';
import CustomTextField from '../../Components/UI/Input/customTextField';
import BottomComponent from '../../Components/UI/bottomComponent';
import { createWallet, getCountryNameCode, loginCall, otpCall } from '../../Config/api';
import { UIStore, updateStore } from '../../Config/store';
import { appendLoggedIn, useQuery } from '../../Helper/helpers';
import { GleapInstance } from '../../Monitoring/Gleap';
import { mixpanel, trackingEvents } from '../../Monitoring/Mixpanel';
import { OTP_COUNTDOWN } from '../../Utils/enums';
import { refreshAccessTokenAtRegularInterval } from '../../Utils/tokenUtils';
import Profile from './profile';

const minDigitsInPhoneNumber = 4;

export default function Auth() {
  const navigate = useNavigate();
  const one = useRef(null);
  const query = useQuery();
  const countDownRef = useRef(null);
  const { state: locationState } = useLocation();
  // const one = useRef(null)
  // const one = useRef(null)
  const [phNo, setPhNo] = React.useState('');
  const [name, setName] = React.useState('');
  const [txId, setTxId] = React.useState('');
  const [otp, setOtp] = React.useState('');
  const [otpSentTime, setOtpSentTime] = React.useState('');
  const [enableResendOtp, setEnableResendOtp] = React.useState(false);
  const [accountExists, setAccountExists] = React.useState(true);
  const [currentState, setCurrentState] = React.useState('main');
  const [trackFields, setTrackFields] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingPh, setLoadingPh] = React.useState(false);
  const [showDetailsForm, setShowDetailsForm] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState('+91');
  const phoneRef = useRef();
  const [flagId, setFlagId] = React.useState('IN');
  const [loadingCountry, setLoadingCountry] = React.useState(true);

  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const buttonOne = useCallback(debounce(async (q = phNo) => {
    setLoadingPh(true);
    setTrackFields([]);
    mixpanel.track(trackingEvents.auth.events.sign.in.started, {
      username: `${countryCode}-${q}`
    });
    loginCall(`${countryCode}-${q}`)
        .then((z) => {
          console.log('z', z);
          if (z?.code === 0) {
            mixpanel.track(trackingEvents.auth.events.sign.in.requestedOTP, {
              username: `${countryCode}-${q}`
            });
            setTxId(z?.data?.txId);
            setCurrentState('otp');
            setAccountExists(true);
            setTrackFields(['otp']);
            setOtpSentTime(new Date());
            setEnableResendOtp(false);
            setTimeout(() => {
              countDownRef?.current?.start();
            }, 1000);
          } else if (z?.code === 105) {
            mixpanel.track(trackingEvents.auth.events.sign.up.requestedOTP, {
              username: `${countryCode}-${q}`
            });
            setCurrentState('otp');
            setTrackFields(['name', 'otp']);

            const id = '';
            mixpanel.track(trackingEvents.auth.events.sign.up.started, {
              username: `${countryCode}-${q}`
            });
            createWallet(id, `${countryCode}-${q}`)
                .then((z) => {
                  console.log(z);
                  setTxId(z?.data?.txId);
                  if (z) {
                    mixpanel.track(trackingEvents.auth.events.sign.up.requestedOTP, {
                      username: `${countryCode}-${q}`
                    });
                    setCurrentState('otp');
                    setTrackFields(['name', 'otp']);
                    setAccountExists(false);
                    setOtp('');
                    setOtpSentTime(new Date());
                    setEnableResendOtp(false);
                    setTimeout(() => {
                      countDownRef?.current?.start();
                    }, 1000);
                  }
                }).finally((z) => {
                  setLoading(false);
                });
          }
        }).finally((z) => {
          setLoading(false);
          setLoadingPh(false);
        });
  }, 1000), [phNo, countryCode]);

  const getIPData = async () => {
    const res = await getCountryNameCode();
    if (res) {
      const countryCallingCode = res.country_calling_code;
      const countryCode = res.country_code;
      if (countryCallingCode && countryCode) {
        setCountryCode(countryCallingCode);
        setFlagId(countryCode);
      };
    };
    setLoadingCountry(false);
  };

  React.useEffect(() => {
    getIPData();
  }, []);


  const state = {
    main: {
      buttonText: null,
      disabled: phNo?.length < minDigitsInPhoneNumber,
      fn: () => buttonOne(phNo),
      buttonText: 'Request OTP',
    },
    name: {
      buttonText: 'Continue',
      fn: () => { },
      disabled: name?.length <= 0
    },
    otp: {
      buttonText: 'Continue',
      disabled: otp?.length <= 5,
      fn: () => otpCall(txId, otp)
          .then(async (x) => {
            if (x) {
              mixpanel.track(trackingEvents.auth.events.sign.in.completed, {
                username: `+91-${phNo}`
              });
              mixpanel.identify(`+91-${phNo}`);
              GleapInstance.identify(`+91-${phNo}`, {
                phone: `+91-${phNo}`
              });
              refreshAccessTokenAtRegularInterval();
              console.log('trackFields.includes(name)', trackFields.includes('name'));
              if (trackFields.includes('name')) {
              // if (true) {
              // await updateWallet(name, x.accessToken);
              // setShowDetailsForm(true);
                updateStore('searchedQuery', locationState);
              // return navigate('/setup?loggedIn=true', { state: locationState, disableBack: true });
              }
              console.log('locationState?.link: otp: ', locationState?.link);
              if (locationState?.link && !locationState?.link.includes('/login')) {
              // updateStore('searchedQuery', {
              //   link: state?.from?.pathname + state.from.search,
              // });
                navigate(appendLoggedIn(locationState.link), { state: { ...locationState.nextState } });
              }

            // window.location.reload()
            // navigate(oauthLink())
            }
          }).finally((z) => {
            setLoading(false);
          }),
    }
  };

  const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
    console.log('hours, minutes, seconds, completed', hours, minutes, seconds, completed);
    if (completed) {
      // Render a completed
      if (!enableResendOtp) {
        // setTimeout(() => {
        setEnableResendOtp(true);
        // }, 1000);
      }
      return (
        <LoadingButton
          loading={loading === 'Resend'}
          sx={{ width: 'fit-content', fontWeight: 700, fontSize: '1.2rem', margin: '0', padding: 0 }}
          onClick={() => {
            // setLoading('Resend')
            (accountExists ? loginCall(`+91-${phNo}`) : createWallet(name, `+91-${phNo}`))
                .then((z) => {
                  mixpanel.track(trackingEvents.auth.events.sign.up.started, {
                    username: `+91-${phNo}`
                  });
                  console.log('z', z);
                  if (z?.code === 0) {
                    mixpanel.track(trackingEvents.auth.events.sign.up.requestedOTP, {
                      username: `+91-${phNo}`
                    });
                    setTxId(z?.data?.txId);
                    window.snackbar('success', 'OTP sent successfully');
                    setOtpSentTime(new Date());
                    setEnableResendOtp(false);
                    setTimeout(() => {
                      countDownRef?.current?.start();
                    }, 1000);
                  }
                }).finally((z) => {
                // setLoading(false)
                });
          }}
        >
          Resend
        </LoadingButton>
      );
    }
    // Render a countdown
    return (
      <span>
        {' '}
        Resend OTP in
        {' '}
        <strong>
          {minutes}
          :
          {seconds}

        </strong>
        {' '}
        second
      </span>
    );
  };

  console.log('locationState', locationState);

  return (
    <SimpleBackground
      sx={{
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        height: '100%',
        padding: '0 1em',
        margin: 'auto'
      }}
      title={'Start your upskilling journey '}>
      {
        showDetailsForm ? <Profile /> : <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant='body1'
            sx={{ width: '100%', textAlign: 'left' }}>
            <b>
              {trackFields.includes('otp') ? `Enter the OTP` : 'Enter your mobile number'}
            </b>
          </Typography>
          {/* <Typography variant='body2' sx={{color: '#8B8686'}}>
            {trackFields.includes('otp') ? `We just sent you a One Time Password on
              your Phone Number(+91-${phNo}). Please enter it to continue` : 'Please sign in/up with your Mobile'}
            .
          </Typography> */}


          {/* <Collapse in={trackFields.includes('name')}>
            <CustomTextField
              fullWidth autoFocus
              sx={{ margin: '1em 0' }}
              id="Name"
              label="Name"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  one.current.click();
                }
              }}
            // // InputProps={{
            // //   startAdornment: <InputAdornment position="start">+91-</InputAdornment>,
            // // }}
            // />

            <LoadingButton trackFields={trackFields === 'Request OTP'}
              ref={one} disabled={!phNo} variant="contained" onClick={() => {

              }}>Request OTP</LoadingButton>
          </Collapse> */}


          {trackFields.includes('otp') ? <CustomTextField
            required
            fullWidth autoFocus
            sx={{ margin: '1em 0' }}
            id="EnterOTP"
            label="Enter OTP"
            value={otp}
            type="text"
            inputProps={{
              maxlength: 6,
              inputmode: 'numeric'
            }}
            onChange={(event) => {
              event.target.value = event.target.value.trimStart();
              if (!isNaN(event.target.value)) {
                if (event.target.value?.length <= 6) {
                  setOtp(event.target.value);
                }
              }
            }}
            helperText={(<Grid container direction="row" sx={{ margin: '0', padding: 0 }}>
              <Typography variant="body2" sx={{ margin: '0', padding: 0 }}>
                Haven’t received OTP?

                <Countdown
                  ref={countDownRef}
                  date={(otpSentTime || new Date()).getTime() + OTP_COUNTDOWN}
                  renderer={countDownRenderer}
                />

              </Typography>
            </Grid>)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                one.current.click();
              }
            }}
          /> : <CustomTextField
            type="text"
            required
            fullWidth autoFocus
            sx={{ margin: '1em 0' }}
            id="PhoneNumber"
            label="Phone Number"
            ref={phoneRef}
            value={phNo}
            onChange={(event) => {
              event.target.value = event.target.value.trimStart();
              if (event.target.value != ' ') {
                if (!isNaN(event.target.value)) {
                  if (event.target.value?.length <= 12) {
                    setPhNo(event.target.value);
                  }
                }
              }
            }}
            InputProps={{
              startAdornment: loadingCountry ? <CircularProgress sx={{ p: 1 }} /> : <InputAdornment
                position="start" sx={{ marginRight: '0px' }}>
                <CountryInput
                  countryCode={countryCode}
                  flagId={flagId}
                  setFlagId={setFlagId}
                  setCountryCode={setCountryCode}
                  phoneRef={phoneRef} />
              </InputAdornment>,
              endAdornment: loadingPh ? <InputAdornment position="end">
                <CircularProgress />
              </InputAdornment> : null,

            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // one.current.click()
                if (phNo?.length >= minDigitsInPhoneNumber) {
                  buttonOne(phNo);
                }
              }
            }}
            inputProps={{
              inputmode: 'numeric'
            }}
          // helperText={(<Grid container direction="row" sx={{ margin: '0px', padding: 0 }}>
          //   <FormControlLabel
          //     control={<Checkbox defaultChecked sx={{ paddingLeft: 0 }} />}
          //     label={(<Typography variant="body2">
          //       {' '}
          //       I agree to the
          //       <a
          //         href="https://www.trential.com/terms" style={{
          //           fontWeight: 600, color: '#044DA1',
          //           textDecoration: 'none',
          //           margin: '0.55ch', padding: 0
          //         }}>
          //         terms and conditions
          //       </a>
          //     </Typography>)} />
          // </Grid>)}
          />}

          <BottomComponent>
            {state[currentState].buttonText === 'Continue' ?
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Button
                  // trackFields={trackFields}
                  sx={{ width: '49%' }}
                  variant="outlined"
                  onClick={() => {
                    setCurrentState('main');
                    setTrackFields([]);
                  }}>
                  Back
                </Button>
                <LoadingButton
                  sx={{ width: '49%', padding: '0.5em', height: '44px' }}
                  // trackFields={trackFields}
                  ref={one}
                  disabled={state[currentState].disabled}
                  variant="contained"
                  id="Submit"
                  onClick={() => {
                    state[currentState].fn();
                  }}
                  datacy="submit">
                  {state[currentState].buttonText}
                </LoadingButton>
              </Grid> : <LoadingButton
                // trackFields={trackFields}
                ref={one}
                disabled={state[currentState].disabled}
                variant="contained"
                id="Submit"
                onClick={() => {
                  state[currentState].fn();
                }}
                datacy="submit">
                {state[currentState].buttonText}
              </LoadingButton>}
          </BottomComponent>

        </Grid>
      }

    </SimpleBackground>
  );
}
